import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Title = makeShortcode("Title");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Title text="Work Experience" mdxType="Title" />
    <table cellSpacing="0" cellPadding="0" style={{
      "display": "block",
      "overflowX": "auto",
      "whiteSpace": "nowrap"
    }}>
      <thead style={{
        "display": "table",
        "width": "95%"
      }}>
  <tr>
    <th width="19%"><img style={{
              "display": "block",
              "margin": "auto",
              "verticalAlign": "top"
            }} src="https://www.shreyravi.com/worldpay.png" height="90px" alt="worldpay"></img></th>
    <th width="19%"><img style={{
              "display": "block",
              "margin": "auto",
              "verticalAlign": "top"
            }} src="https://www.shreyravi.com/deloitte.png" height="90px" alt="deloitte"></img></th>
    <th width="19%"><img style={{
              "display": "block",
              "margin": "auto",
              "verticalAlign": "top"
            }} src="https://www.shreyravi.com/morganstanley.png" height="60px" alt="morgan stanley"></img></th>
    <th width="19%"><img style={{
              "display": "block",
              "margin": "auto",
              "verticalAlign": "top"
            }} src="https://www.shreyravi.com/nk.png" height="60px" alt="nested knowledge"></img></th>
    <th width="19%"><img style={{
              "display": "block",
              "margin": "auto",
              "verticalAlign": "top"
            }} src="https://www.shreyravi.com/calyxcapitalpartners.png" height="60px" alt="calyx"></img></th>
  </tr>
      </thead>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      