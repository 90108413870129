import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Title = makeShortcode("Title");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <img {...{
      "src": "https://www.shreyravi.com/profilePicture.jpg",
      "style": {
        "borderRadius": "50%",
        "width": "130px",
        "flex": "0 0 20%",
        "marginRight": "30px"
      }
    }}></img>
    <h2>{`Hi, I'm Shrey Ravi.`}</h2>
    <p>{`I am the creator of `}<a parentName="p" {...{
        "href": "https://chrome.google.com/webstore/detail/work-mode-block-all-socia/dmijhfnjdfpaanlbahmklnhjkbhegepm"
      }}>{`WorkMode`}</a>{` & `}<a parentName="p" {...{
        "href": "https://shreyravi.github.io/walk-safe-home/"
      }}>{`WalkSafe`}</a>{`, an Analyst at `}<a parentName="p" {...{
        "href": "https://www2.deloitte.com/global/en/services/consulting-deloitte.html"
      }}>{`Deloitte`}</a>{`, (alternate) history writer, flag connoisseur, and a `}<a parentName="p" {...{
        "href": "https://github.com/ShreyRavi"
      }}>{`programmer`}</a>{`. I'm a fan of my dog, `}<a parentName="p" {...{
        "href": "https://www.shreyravi.com/rocky"
      }}>{`Rocky`}</a>{`, and sometimes do amateur standup comedy. You can contact me at this form `}<a parentName="p" {...{
        "href": "https://www.shreyravi.com/contact"
      }}>{`here`}</a>{`.`}</p>
    <br />
    <Title text="What Does Shrey Do?" mdxType="Title" />
    <h5>{`Consultant (Analyst) @ Deloitte`}</h5>
    <p>{`Deloitte is a consulting company where I'm helping our clients strategize their technology and business strategy while also gaining valuable real-world experience.`}</p>
    <p>{`You can see my previous work experience on my `}<a parentName="p" {...{
        "href": "https://www.linkedin.com/in/shreyravi/"
      }}>{`LinkedIn`}</a>{`.`}</p>
    <Title text="Okay, But What Else?" mdxType="Title" />
    <h5>{`Programmer (Creator of WorkMode, WalkSafe, etc.)`}</h5>
    <p>{`I love to make tools and products that solve problems I see every day. This includes `}<a parentName="p" {...{
        "href": "https://chrome.google.com/webstore/detail/work-mode-block-all-socia/dmijhfnjdfpaanlbahmklnhjkbhegepm"
      }}>{`WorkMode`}</a>{`, a Chrome Extension I made to block time-wasting websites that has 20,000+ users today, and WalkSafe, a personal safety app.`}</p>
    <p>{`You can see my projects on my GitHub `}<a parentName="p" {...{
        "href": "https://github.com/ShreyRavi"
      }}>{`here`}</a>{`.`}</p>
    <h5>{`Partner @ REDSA`}</h5>
    <p>{`REDSA is a real estate venture that I've been working on since 2016. We are raising a fund and investing in multi-family rental properties in LCOL areas.`}</p>
    <Title text="What About Fun?" mdxType="Title" />
    <p>{`Amateur Standup Comedy
Emphasis on the amateur. I started going to open mic nights in Summer 2019 and am aiming to perform and record my Tight Five routine to post online.`}</p>
    <h5>{`Flag Connoisseur`}</h5>
    <p>{`I'm a member of `}<a parentName="p" {...{
        "href": "https://nava.org/"
      }}>{`NAVA (North American Vexillological Association)`}</a>{`, the largest group on flag design. Click `}<a parentName="p" {...{
        "href": "https://www.shreyravi.com/flag-rankings"
      }}>{`here`}</a>{` to read about my ramblings on flags.`}</p>
    <h5>{`Writer/Reading`}</h5>
    <p>{`I write a lot, especially on history, politics, business, and general life lessons I've picked up. I also maintain a `}<a parentName="p" {...{
        "href": "https://www.shreyravi.com/reading-hall-of-fame"
      }}>{`Reading Hall of Fame`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      